<template>
  <v-card class="py-2 px-3">
    <div class="">
      <p class="ms-0 my-0 f-sanspro fw-600 fs-10pt" v-cHex="hexShades(cor.active.B,0.5)">
        <i class="btn-icon-left" v-i:duo#chart-line#18></i>
        UCs Ofertas X Professores (2021/2)
      </p>
    </div>
    <v-card class="py-1 px-2 mt-3" v-bg:b#4 @click="$emit('cardClick')">
      <div class="my-1 mx-0 flexb falign-center" style="justify-content: space-between;">
        <div class="">
          <p class="ms-0 my-0 f-sanspro fw-600 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
            <i class="btn-icon-left" v-i:duo#graduation-cap#16></i>
            <span class="">UCs com ofertas</span>
            <span class="ms-3 f-sanspro fs-12pt fw-800">{{Object.keys(ucsOfertadas).length}}</span>
          </p>
          <p class="ms-0 my-0 f-sanspro fw-600 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
            <i class="btn-icon-left" v-i:duo#users#16></i>
            <span class="">Professores indicados</span>
            <span class="ms-3 f-sanspro fs-12pt fw-800">{{Object.keys(profsOfertas).length}}</span>
          </p>
        </div>
        <div class="text-center">
          <p class="ms-0 mb-2 f-sanspro fw-600 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
            <span class="">Média profs / UC</span>
          </p>
          <p class="ms-0 my-0 mt-n1 f-roboto fw-500 fs-13pt" v-cHex="hexShades(cor.active.B,1)">
            <span class="border px-1 rounded">{{parseFloat(Object.keys(profsOfertas).length / Object.keys(ucsOfertadas).length).toFixed(1)}}</span>
          </p>
        </div>
      </div>
    </v-card>
    <div class="mt-3">
      <p class="ms-0 my-0 f-sanspro fw-400 fs-9pt" v-cHex="cor.active.I">
        <span class="ms-1 absolute pt-n1" v-i:ic#circle#12 v-c:I style="left: 6px;"></span>
        <span class="ms-3 fw-600 fs-11pt">Qtde de UCs</span> ofertadas pela
        <span v-c:K class="ms-0 rounded border px-1 py-1 fs-8pt f-roboto fw-600">Qtde de professores</span>
      </p>
      <v-row class="p-0 mx-1 mt-1 mb-0" v-if="false">
        <v-col cols="2" class="m-0 p-0" v-for="idx in [0,1,2,3,4,5]" :key="idx">
          <span class="fs-8pt f-roboto fw-800">{{labelDias[idx]}}</span>
          <span class="ms-1 fs-7pt f-raleway fw-300" v-if="labelDiasSem[idx] != undefined">{{labelDiasSem[idx].toUpperCase()}}</span>
        </v-col>
      </v-row>
      <v-sheet color="transparent" class="mt-n1">
        <v-sparkline :value="distribuicao" label-size="12" :color="cor.active.I" height="80" padding="14" auto-draw smooth>
          <template v-slot:label="item">
            {{item.value}}
          </template>
        </v-sparkline>
      </v-sheet>
      <v-row class="p-0 mx-1 mt-1 mb-0">
        <v-col cols="12" class="m-0 p-0">
          <div class="my-1 mx-0 flexb falign-center" style="justify-content: space-between;">
            <v-card class="w30 p-0 text-center" v-for="(label,index) in distribuicaoLabels" :key="label">
              <v-tooltip bottom open-delay="1200">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <span v-if="index<3" class="fs-7pt f-roboto fw-600">{{label}}</span>
                    <span v-if="index>=3" class="fs-7pt f-roboto fw-600">{{label}}</span>
                  </span>
                </template>
                <p class="p-0 m-0 f-roboto fs-8pt" v-for="(uc,idx) in distribuicaoUCs[index]" :key="idx" v-if="idx < 12">
                  {{uc[Object.keys(uc)[0]].ucNome}}
                </p>
                <p class="p-0 m-0 f-roboto fw-800 fs-8pt" v-if="distribuicaoUCs[index].length >= 12">
                  mais {{distribuicaoUCs[index].length - 12}} não listados
                </p>
              </v-tooltip>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <div class="ms-0 mt-3 mb-1 text-right f-raleway fw-500 fs-12pt" v-cHex="hexShades(cor.active.B,0.5)">
        <v-btn @click="$emit('cardClick')" class="text-nonecase f-sanspro fw-400 fs-8pt" v-c:I outlined x-small>
          ver detalhes
        </v-btn>
      </div>
    </div>
    <v-overlay absolute :value="loading">
      <div class="text-center">
        <v-progress-circular color="white" indeterminate size="64"></v-progress-circular>
      </div>
      <div class="text-center mb-4" style="margin-top: 20px;">
        <img width="180px" class="mt-n1" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
      </div>
    </v-overlay>
  </v-card>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import moment from "moment";

export default {
  name: "carducsprofs",
  components: {},
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      pessoasOnline: [],
      pessoasTotal: 0,
      distribuicao: [],
      distribuicaoUCs: [],
      distribuicaoLabels: [],
      labelDiasSem: [],
      ucsOfertadas: {},
      ofertas: {},
      profsOfertas: {},
      profsPorUc: {},
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.loading = true;

    rdb.ref('/curriculo/ucs').orderByChild("oferta2021S2").equalTo(true).on('value',function(snapshot) {
      self.ucsOfertadas = snapshot.val();
      console.log("self.ucsOfertadas",self.ucsOfertadas);
    });

    rdb.ref('/curriculo/ofertas/2021S2').on('value',function(snapshot) {
      self.ofertas = snapshot.val();
      console.log("self.ofertas",self.ofertas);
      self.profsOfertas = {};
      for(var key in self.ofertas) {
        if(self.profsOfertas[self.ofertas[key].profID] == undefined) {
          self.profsOfertas[self.ofertas[key].profID] = [];
        }
        self.profsOfertas[self.ofertas[key].profID].push(self.ofertas[key]);

      }
      console.log("self.profsOfertas",self.profsOfertas);
      self.build();
    });


  },
  methods:{

    build() {
      var self = this;
      self.loading = true;

      console.log("build");
      console.log("self.ucsOfertadas",self.ucsOfertadas);
      console.log("qtde self.ucsOfertadas",Object.keys(self.ucsOfertadas).length);
      console.log("self.ofertas",self.ofertas);
      console.log("qtde self.ofertas",Object.keys(self.ofertas).length);

      var ucsNE = [];
      self.profsPorUc = {};

      for(var key in self.ofertas) {
        if(self.ucsOfertadas[self.ofertas[key].ucID] == undefined) {
          ucsNE.push(self.ofertas[key].ucID+" "+self.ofertas[key].NOM_DISCIPLINA);
        } else {
          if(self.profsPorUc[self.ofertas[key].ucID] == undefined) {
            self.profsPorUc[self.ofertas[key].ucID] = {};
          }
          self.profsPorUc[self.ofertas[key].ucID][self.ofertas[key].profID] = {
            ucNome: self.ucsOfertadas[self.ofertas[key].ucID].nome,
            ucID: self.ofertas[key].ucID,
            profNome: self.ofertas[key].NOM_PROFESSOR,
            profID: self.ofertas[key].profID,
          }
        }
      }
      console.log("self.profsPorUc",self.profsPorUc);
      console.log("qtde self.profsPorUc",Object.keys(self.profsPorUc).length);
      console.log("ucsNE (ofertas com UCs sem A2)",ucsNE.length);

      self.distribuicao = [ 0, 0, 0, 0, 0, 0, 0 ];
      self.distribuicaoLabels = [ "1", "2", "3", "4", "5-8", "9-16", "+16"];
      self.distribuicaoUCs = [ [], [], [], [], [], [], [] ];

      for(var key in self.profsPorUc) {
        var qtdeProfs = Object.keys(self.profsPorUc[key]).length;
        if(qtdeProfs <= 1) {
          self.distribuicao[0]++;
          self.distribuicaoUCs[0].push(self.profsPorUc[key]);
        }
        if(qtdeProfs > 1 && qtdeProfs <= 2) {
          self.distribuicao[1]++;
          self.distribuicaoUCs[1].push(self.profsPorUc[key]);
        }
        if(qtdeProfs > 2 && qtdeProfs <= 3) {
          self.distribuicao[2]++;
          self.distribuicaoUCs[2].push(self.profsPorUc[key]);
        }
        if(qtdeProfs > 3 && qtdeProfs <= 4) {
          self.distribuicao[3]++;
          self.distribuicaoUCs[3].push(self.profsPorUc[key]);
        }
        if(qtdeProfs > 4 && qtdeProfs <= 8) {
          self.distribuicao[4]++;
          self.distribuicaoUCs[4].push(self.profsPorUc[key]);
        }
        if(qtdeProfs > 8 && qtdeProfs <= 16) {
          self.distribuicao[5]++;
          self.distribuicaoUCs[5].push(self.profsPorUc[key]);
        }
        if(qtdeProfs > 16) {
          self.distribuicao[6]++;
          self.distribuicaoUCs[6].push(self.profsPorUc[key]);
        }
      }
      console.log("self.distribuicao",self.distribuicao);
      console.log("self.distribuicaoUCs",self.distribuicaoUCs);
      self.loading = false;

    }
  }
}
</script>

<style scoped>
</style>
